import React from "react";

export const Burger = (props, active, setActive) =>{

    let content = {
        English: {
            link_1: "Services",
            link_2: "Technologies",
            link_3: "Cases",
            link_4: "Team",
            link_5: "FAQ",
            link_6: "Contacts"
        },
        Russian: {
            link_1: "Услуги",
            link_2: "Технологии",
            link_3: "Кейсы",
            link_4: "Команда",
            link_5: "FAQ",
            link_6: "Контакты"
        }
      };

      props.language === "Russian"
        ? (content = content.Russian)
        : (content = content.English);

    return (
        <div className={active ? 'menu_bg active' : 'menu_bg'} onClick={()=>setActive(false)}>
        
            <div className="menu_content">
                <ul>
                    <a href=""><li>{content.link_1}</li></a>
                    <a href=""><li>{content.link_2}</li></a>
                    <a href=""><li>{content.link_3}</li></a>
                    <a href=""><li>{content.link_4}</li></a>
                    <a href=""><li>{content.link_5}</li></a>
                </ul>
            </div>
        </div>
    )
}

export default Burger;