import React, { Component } from "react";

class Footer extends React.Component {
    render() {
         return (
        <div className="footer">
            <p>YanAgency.com <br /> 2021</p>
        </div>
     );
    }
}

export default Footer;