import React, { Component } from "react";
import Slider from "react-slick";
import team1 from '../img/team1.png' // relative path to image 

class Team extends React.Component {
    render() {
    let content = {
        English: {
            team_head: "Meet the team"
        },
        Russian: {
            team_head: "Наша команда"        
        }
    };

    this.props.language === "Russian"
        ? (content = content.Russian)
        : (content = content.English);

    
    var settings = {
            dots: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: true,
            /*responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]*/
    };

     return (
        <div className="team-slider">
        <div className="container">
          <div className="team_head">
              <h2>{content.team_head}</h2>
          </div>
          <Slider {...settings}>
              <div className="team-slide">
                  <img src={team1} alt="" />
                  <div className="team-info">
                      <div className="team-info-line1"><p>UX/UI, cofounder</p></div>
                      <div className="team-info-line2">
                          <a href="">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0)">
                                  <path d="M1.5002 1.63305C-0.385801 3.59205 0.000199258 5.67305 0.000199258 11.995C0.000199258 17.245 -0.915801 22.508 3.8782 23.747C5.3752 24.132 18.6392 24.132 20.1342 23.745C22.1302 23.23 23.7542 21.611 23.9762 18.788C24.0072 18.394 24.0072 5.60305 23.9752 5.20105C23.7392 2.19405 21.8882 0.461046 19.4492 0.110046C18.8902 0.0290464 18.7782 0.00504639 15.9102 4.63948e-05C5.7372 0.00504639 3.5072 -0.447954 1.5002 1.63305Z" fill="url(#paint0_linear)"/>
                                  <path d="M11.9978 3.13905C8.36681 3.13905 4.91881 2.81605 3.60181 6.19605C3.05781 7.59205 3.13681 9.40505 3.13681 12.001C3.13681 14.279 3.06381 16.42 3.60181 17.805C4.91581 21.187 8.39181 20.863 11.9958 20.863C15.4728 20.863 19.0578 21.225 20.3908 17.805C20.9358 16.395 20.8558 14.609 20.8558 12.001C20.8558 8.53905 21.0468 6.30405 19.3678 4.62605C17.6678 2.92605 15.3688 3.13905 11.9938 3.13905H11.9978ZM11.2038 4.73605C18.7778 4.72405 19.7418 3.88205 19.2098 15.579C19.0208 19.716 15.8708 19.262 11.9988 19.262C4.93881 19.262 4.73581 19.06 4.73581 11.997C4.73581 4.85205 5.29581 4.74005 11.2038 4.73405V4.73605ZM16.7278 6.20705C16.1408 6.20705 15.6648 6.68305 15.6648 7.27005C15.6648 7.85705 16.1408 8.33305 16.7278 8.33305C17.3148 8.33305 17.7908 7.85705 17.7908 7.27005C17.7908 6.68305 17.3148 6.20705 16.7278 6.20705ZM11.9978 7.45005C9.48481 7.45005 7.44781 9.48805 7.44781 12.001C7.44781 14.514 9.48481 16.551 11.9978 16.551C14.5108 16.551 16.5468 14.514 16.5468 12.001C16.5468 9.48805 14.5108 7.45005 11.9978 7.45005ZM11.9978 9.04705C15.9028 9.04705 15.9078 14.955 11.9978 14.955C8.09381 14.955 8.08781 9.04705 11.9978 9.04705Z" fill="white"/>
                                  </g>
                                  <defs>
                                  <linearGradient id="paint0_linear" x1="1.54622" y1="22.4671" x2="23.8517" y2="3.16202" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#FFDD55"/>
                                  <stop offset="0.5" stop-color="#FF543E"/>
                                  <stop offset="1" stop-color="#C837AB"/>
                                  </linearGradient>
                                  <clipPath id="clip0">
                                  <rect width="24" height="24" fill="white"/>
                                  </clipPath>
                                  </defs>
                              </svg>
                          </a>
                          <p>yanchukkz</p>
                      </div>
                  </div>
              </div>
              <div className="team-slide">
                  <img src={team1} alt="" />
                  <div className="team-info">
                      <div className="team-info-line1"><p>UX/UI, cofounder</p></div>
                      <div className="team-info-line2">
                          <a href="">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0)">
                                  <path d="M1.5002 1.63305C-0.385801 3.59205 0.000199258 5.67305 0.000199258 11.995C0.000199258 17.245 -0.915801 22.508 3.8782 23.747C5.3752 24.132 18.6392 24.132 20.1342 23.745C22.1302 23.23 23.7542 21.611 23.9762 18.788C24.0072 18.394 24.0072 5.60305 23.9752 5.20105C23.7392 2.19405 21.8882 0.461046 19.4492 0.110046C18.8902 0.0290464 18.7782 0.00504639 15.9102 4.63948e-05C5.7372 0.00504639 3.5072 -0.447954 1.5002 1.63305Z" fill="url(#paint0_linear)"/>
                                  <path d="M11.9978 3.13905C8.36681 3.13905 4.91881 2.81605 3.60181 6.19605C3.05781 7.59205 3.13681 9.40505 3.13681 12.001C3.13681 14.279 3.06381 16.42 3.60181 17.805C4.91581 21.187 8.39181 20.863 11.9958 20.863C15.4728 20.863 19.0578 21.225 20.3908 17.805C20.9358 16.395 20.8558 14.609 20.8558 12.001C20.8558 8.53905 21.0468 6.30405 19.3678 4.62605C17.6678 2.92605 15.3688 3.13905 11.9938 3.13905H11.9978ZM11.2038 4.73605C18.7778 4.72405 19.7418 3.88205 19.2098 15.579C19.0208 19.716 15.8708 19.262 11.9988 19.262C4.93881 19.262 4.73581 19.06 4.73581 11.997C4.73581 4.85205 5.29581 4.74005 11.2038 4.73405V4.73605ZM16.7278 6.20705C16.1408 6.20705 15.6648 6.68305 15.6648 7.27005C15.6648 7.85705 16.1408 8.33305 16.7278 8.33305C17.3148 8.33305 17.7908 7.85705 17.7908 7.27005C17.7908 6.68305 17.3148 6.20705 16.7278 6.20705ZM11.9978 7.45005C9.48481 7.45005 7.44781 9.48805 7.44781 12.001C7.44781 14.514 9.48481 16.551 11.9978 16.551C14.5108 16.551 16.5468 14.514 16.5468 12.001C16.5468 9.48805 14.5108 7.45005 11.9978 7.45005ZM11.9978 9.04705C15.9028 9.04705 15.9078 14.955 11.9978 14.955C8.09381 14.955 8.08781 9.04705 11.9978 9.04705Z" fill="white"/>
                                  </g>
                                  <defs>
                                  <linearGradient id="paint0_linear" x1="1.54622" y1="22.4671" x2="23.8517" y2="3.16202" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#FFDD55"/>
                                  <stop offset="0.5" stop-color="#FF543E"/>
                                  <stop offset="1" stop-color="#C837AB"/>
                                  </linearGradient>
                                  <clipPath id="clip0">
                                  <rect width="24" height="24" fill="white"/>
                                  </clipPath>
                                  </defs>
                              </svg>
                          </a>
                          <p>yanchukkz</p>
                      </div>
                  </div>
              </div>
              <div className="team-slide">
                  <img src={team1} alt="" />
                  <div className="team-info">
                      <div className="team-info-line1"><p>UX/UI, cofounder</p></div>
                      <div className="team-info-line2">
                          <a href="">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0)">
                                  <path d="M1.5002 1.63305C-0.385801 3.59205 0.000199258 5.67305 0.000199258 11.995C0.000199258 17.245 -0.915801 22.508 3.8782 23.747C5.3752 24.132 18.6392 24.132 20.1342 23.745C22.1302 23.23 23.7542 21.611 23.9762 18.788C24.0072 18.394 24.0072 5.60305 23.9752 5.20105C23.7392 2.19405 21.8882 0.461046 19.4492 0.110046C18.8902 0.0290464 18.7782 0.00504639 15.9102 4.63948e-05C5.7372 0.00504639 3.5072 -0.447954 1.5002 1.63305Z" fill="url(#paint0_linear)"/>
                                  <path d="M11.9978 3.13905C8.36681 3.13905 4.91881 2.81605 3.60181 6.19605C3.05781 7.59205 3.13681 9.40505 3.13681 12.001C3.13681 14.279 3.06381 16.42 3.60181 17.805C4.91581 21.187 8.39181 20.863 11.9958 20.863C15.4728 20.863 19.0578 21.225 20.3908 17.805C20.9358 16.395 20.8558 14.609 20.8558 12.001C20.8558 8.53905 21.0468 6.30405 19.3678 4.62605C17.6678 2.92605 15.3688 3.13905 11.9938 3.13905H11.9978ZM11.2038 4.73605C18.7778 4.72405 19.7418 3.88205 19.2098 15.579C19.0208 19.716 15.8708 19.262 11.9988 19.262C4.93881 19.262 4.73581 19.06 4.73581 11.997C4.73581 4.85205 5.29581 4.74005 11.2038 4.73405V4.73605ZM16.7278 6.20705C16.1408 6.20705 15.6648 6.68305 15.6648 7.27005C15.6648 7.85705 16.1408 8.33305 16.7278 8.33305C17.3148 8.33305 17.7908 7.85705 17.7908 7.27005C17.7908 6.68305 17.3148 6.20705 16.7278 6.20705ZM11.9978 7.45005C9.48481 7.45005 7.44781 9.48805 7.44781 12.001C7.44781 14.514 9.48481 16.551 11.9978 16.551C14.5108 16.551 16.5468 14.514 16.5468 12.001C16.5468 9.48805 14.5108 7.45005 11.9978 7.45005ZM11.9978 9.04705C15.9028 9.04705 15.9078 14.955 11.9978 14.955C8.09381 14.955 8.08781 9.04705 11.9978 9.04705Z" fill="white"/>
                                  </g>
                                  <defs>
                                  <linearGradient id="paint0_linear" x1="1.54622" y1="22.4671" x2="23.8517" y2="3.16202" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#FFDD55"/>
                                  <stop offset="0.5" stop-color="#FF543E"/>
                                  <stop offset="1" stop-color="#C837AB"/>
                                  </linearGradient>
                                  <clipPath id="clip0">
                                  <rect width="24" height="24" fill="white"/>
                                  </clipPath>
                                  </defs>
                              </svg>
                          </a>
                          <p>yanchukkz</p>
                      </div>
                  </div>
              </div>
              <div className="team-slide">
                  <img src={team1} alt="" />
                  <div className="team-info">
                      <div className="team-info-line1"><p>UX/UI, cofounder</p></div>
                      <div className="team-info-line2">
                          <a href="">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0)">
                                  <path d="M1.5002 1.63305C-0.385801 3.59205 0.000199258 5.67305 0.000199258 11.995C0.000199258 17.245 -0.915801 22.508 3.8782 23.747C5.3752 24.132 18.6392 24.132 20.1342 23.745C22.1302 23.23 23.7542 21.611 23.9762 18.788C24.0072 18.394 24.0072 5.60305 23.9752 5.20105C23.7392 2.19405 21.8882 0.461046 19.4492 0.110046C18.8902 0.0290464 18.7782 0.00504639 15.9102 4.63948e-05C5.7372 0.00504639 3.5072 -0.447954 1.5002 1.63305Z" fill="url(#paint0_linear)"/>
                                  <path d="M11.9978 3.13905C8.36681 3.13905 4.91881 2.81605 3.60181 6.19605C3.05781 7.59205 3.13681 9.40505 3.13681 12.001C3.13681 14.279 3.06381 16.42 3.60181 17.805C4.91581 21.187 8.39181 20.863 11.9958 20.863C15.4728 20.863 19.0578 21.225 20.3908 17.805C20.9358 16.395 20.8558 14.609 20.8558 12.001C20.8558 8.53905 21.0468 6.30405 19.3678 4.62605C17.6678 2.92605 15.3688 3.13905 11.9938 3.13905H11.9978ZM11.2038 4.73605C18.7778 4.72405 19.7418 3.88205 19.2098 15.579C19.0208 19.716 15.8708 19.262 11.9988 19.262C4.93881 19.262 4.73581 19.06 4.73581 11.997C4.73581 4.85205 5.29581 4.74005 11.2038 4.73405V4.73605ZM16.7278 6.20705C16.1408 6.20705 15.6648 6.68305 15.6648 7.27005C15.6648 7.85705 16.1408 8.33305 16.7278 8.33305C17.3148 8.33305 17.7908 7.85705 17.7908 7.27005C17.7908 6.68305 17.3148 6.20705 16.7278 6.20705ZM11.9978 7.45005C9.48481 7.45005 7.44781 9.48805 7.44781 12.001C7.44781 14.514 9.48481 16.551 11.9978 16.551C14.5108 16.551 16.5468 14.514 16.5468 12.001C16.5468 9.48805 14.5108 7.45005 11.9978 7.45005ZM11.9978 9.04705C15.9028 9.04705 15.9078 14.955 11.9978 14.955C8.09381 14.955 8.08781 9.04705 11.9978 9.04705Z" fill="white"/>
                                  </g>
                                  <defs>
                                  <linearGradient id="paint0_linear" x1="1.54622" y1="22.4671" x2="23.8517" y2="3.16202" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#FFDD55"/>
                                  <stop offset="0.5" stop-color="#FF543E"/>
                                  <stop offset="1" stop-color="#C837AB"/>
                                  </linearGradient>
                                  <clipPath id="clip0">
                                  <rect width="24" height="24" fill="white"/>
                                  </clipPath>
                                  </defs>
                              </svg>
                          </a>
                          <p>yanchukkz</p>
                      </div>
                  </div>
              </div>
              <div className="team-slide">
                  <img src={team1} alt="" />
                  <div className="team-info">
                      <div className="team-info-line1"><p>UX/UI, cofounder</p></div>
                      <div className="team-info-line2">
                          <a href="">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0)">
                                  <path d="M1.5002 1.63305C-0.385801 3.59205 0.000199258 5.67305 0.000199258 11.995C0.000199258 17.245 -0.915801 22.508 3.8782 23.747C5.3752 24.132 18.6392 24.132 20.1342 23.745C22.1302 23.23 23.7542 21.611 23.9762 18.788C24.0072 18.394 24.0072 5.60305 23.9752 5.20105C23.7392 2.19405 21.8882 0.461046 19.4492 0.110046C18.8902 0.0290464 18.7782 0.00504639 15.9102 4.63948e-05C5.7372 0.00504639 3.5072 -0.447954 1.5002 1.63305Z" fill="url(#paint0_linear)"/>
                                  <path d="M11.9978 3.13905C8.36681 3.13905 4.91881 2.81605 3.60181 6.19605C3.05781 7.59205 3.13681 9.40505 3.13681 12.001C3.13681 14.279 3.06381 16.42 3.60181 17.805C4.91581 21.187 8.39181 20.863 11.9958 20.863C15.4728 20.863 19.0578 21.225 20.3908 17.805C20.9358 16.395 20.8558 14.609 20.8558 12.001C20.8558 8.53905 21.0468 6.30405 19.3678 4.62605C17.6678 2.92605 15.3688 3.13905 11.9938 3.13905H11.9978ZM11.2038 4.73605C18.7778 4.72405 19.7418 3.88205 19.2098 15.579C19.0208 19.716 15.8708 19.262 11.9988 19.262C4.93881 19.262 4.73581 19.06 4.73581 11.997C4.73581 4.85205 5.29581 4.74005 11.2038 4.73405V4.73605ZM16.7278 6.20705C16.1408 6.20705 15.6648 6.68305 15.6648 7.27005C15.6648 7.85705 16.1408 8.33305 16.7278 8.33305C17.3148 8.33305 17.7908 7.85705 17.7908 7.27005C17.7908 6.68305 17.3148 6.20705 16.7278 6.20705ZM11.9978 7.45005C9.48481 7.45005 7.44781 9.48805 7.44781 12.001C7.44781 14.514 9.48481 16.551 11.9978 16.551C14.5108 16.551 16.5468 14.514 16.5468 12.001C16.5468 9.48805 14.5108 7.45005 11.9978 7.45005ZM11.9978 9.04705C15.9028 9.04705 15.9078 14.955 11.9978 14.955C8.09381 14.955 8.08781 9.04705 11.9978 9.04705Z" fill="white"/>
                                  </g>
                                  <defs>
                                  <linearGradient id="paint0_linear" x1="1.54622" y1="22.4671" x2="23.8517" y2="3.16202" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#FFDD55"/>
                                  <stop offset="0.5" stop-color="#FF543E"/>
                                  <stop offset="1" stop-color="#C837AB"/>
                                  </linearGradient>
                                  <clipPath id="clip0">
                                  <rect width="24" height="24" fill="white"/>
                                  </clipPath>
                                  </defs>
                              </svg>
                          </a>
                          <p>yanchukkz</p>
                      </div>
                  </div>
              </div>
            
          </Slider>
       </div>
    </div>
     );
    }
}

export default Team;