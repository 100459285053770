import React from "react";
import maket1 from '../img/maket-1.png' // relative path to image 
import maket2 from '../img/maket-2.png' // relative path to image 
import maket3 from '../img/maket-3.png' // relative path to image 

export function Services(props) {
  let content = {
    English: {
        head1: "Web Development",
        head2: "UI&UX Design",
        head3: "SEO Optimization",
        text1: "We create unique websites and services with awesome frontend and backend solutions.",
        text2: "We create unique websites and services with awesome frontend and backend solutions.",
        text3: "We create unique websites and services with awesome frontend and backend solutions.",
        link: "learn more"
    },
    Russian: {
        head1: "Веб разработка",
        head2: "UI&UX дизайн",
        head3: "SEO оптимизация",
        text1: "Мы разрабатываем оригинальные сервисы с использованием лучших наработок в frontend и backend разработки.",
        text2: "Мы создаем уникальный дизайн без использования шаблонов для сайтов, сервисов и мобильных приложений.",
        text3: "Проводим комплексную SEO оптимизацию сайтов, в которую входят ускорение работы сайта, оптимизация контента, оптимизация кода, снижение нагрузки на сервера и т.д.",
        link: "Смотреть больше"
    }
  };

  props.language === "Russian"
    ? (content = content.Russian)
    : (content = content.English);

  return (
    <div className="services">
        <div className="container">
            <div className="services_line">
              <div className="services_img">
                <img src={maket1} alt={"laptop"}/> 
              </div>
              <div className="services_text">
                <h2>{content.head1}</h2>
                <p>{content.text1}</p>
                <a href="">
                  <p>{content.link}</p>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.22203 22L18 12L8.22203 2L7 3.24978L15.5559 12L7 20.7502L8.22203 22Z" fill="white"/>
                  </svg>
                </a>
              </div>
            </div>
            <div className="services_line services_line2">
              <div className="services_img">
                <img src={maket2} alt={"laptop"}/> 
              </div>
              <div className="services_text">
                <h2>{content.head2}</h2>
                <p>{content.text3}</p>
                <a href="">
                  <p>{content.link}</p>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.22203 22L18 12L8.22203 2L7 3.24978L15.5559 12L7 20.7502L8.22203 22Z" fill="white"/>
                  </svg>
                </a>
              </div>
            </div>
            <div className="services_line">
              <div className="services_img">
                <img src={maket3} alt={"laptop"}/> 
              </div>
              <div className="services_text">
                <h2>{content.head2}</h2>
                <p>{content.text3}</p>
                <a href="">
                  <p>{content.link}</p>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.22203 22L18 12L8.22203 2L7 3.24978L15.5559 12L7 20.7502L8.22203 22Z" fill="white"/>
                  </svg>
                </a>
              </div>
            </div>
            
        </div>
    </div>
  );
}

export default Services;