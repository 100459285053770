import React, { Component } from "react";
import Slider from "react-slick";
import logo3 from '../img/logo3.png' // relative path to image 

class Reviews extends React.Component {
    render() {
    let content = {
        English: {
            reviews_head: 'What our clients say',
            reviews_text: 'We help small and medium businesses develop web and mobile services through rapid prototyping, iterative development, design thinking and agile devops.',
            reviews_text_1: "We help small and medium businesses develop web and mobile services through rapid prototyping, iterative development, design thinking and agile devops.",
            reviews_author_1: "Andrey Kim, Chen-Laoshi",
        },
        Russian: {
            reviews_head: 'Что говорят клиенты', 
            reviews_text: 'Мы помогаем малому и среднему бизнесу разрабатывать веб-сервисы и мобильные сервисы',
            reviews_text_1: "We help small and medium businesses develop web and mobile services through rapid prototyping, iterative development, design thinking and agile devops.",
            reviews_author_1: "Andrey Kim, Chen-Laoshi",

        }
    };

    this.props.language === "Russian"
        ? (content = content.Russian)
        : (content = content.English);

    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
         /*responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]*/
        };

     return (
         <div className="reviews">
            <div className="container">
                <div className="reviews-main">
                    <div className="reviews-text">
                        <h2>{content.reviews_head}</h2>
                        <p>{content.reviews_text}</p>
                    </div>
                    <div className="reviews-slider">
                        <h2>{content.reviews_head}</h2>
                        <Slider {...settings}>
                            <div className="reviews_slide">
                                <div className="reviews-slide-text">
                                    <p>{content.reviews_text_1}</p>
                                </div>
                                <div className="reviews-slide-author">
                                    <img src={logo3} alt="" />
                                    <p>{content.reviews_author_1}</p>
                                </div>
                            </div>
                            <div className="reviews_slide">
                                <div className="reviews-slide-text">
                                    <p>{content.reviews_text_1}</p>
                                </div>
                                <div className="reviews-slide-author">
                                    <img src={logo3} alt="" />
                                    <p>{content.reviews_author_1}</p>
                                </div>
                            </div>
                            <div className="reviews_slide">
                                <div className="reviews-slide-text">
                                    <p>{content.reviews_text_1}</p>
                                </div>
                                <div className="reviews-slide-author">
                                    <img src={logo3} alt="" />
                                    <p>{content.reviews_author_1}</p>
                                </div>
                            </div>
                            <div className="reviews_slide">
                                <div className="reviews-slide-text">
                                    <p>{content.reviews_text_1}</p>
                                </div>
                                <div className="reviews-slide-author">
                                    <img src={logo3} alt="" />
                                    <p>{content.reviews_author_1}</p>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
         </div>
     );
    }
}

export default Reviews;