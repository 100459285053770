import React, { Component } from "react";

class Form extends React.Component {
    render() {
    let content = {
        English: {
            contacts_head: "Let's Talk",
            label1:'Your Name*',
            input1: "John Smith",
            label2:'Email*',
            input2: "email@email.com",
            label3:'Phone',
            input3: "+0 000 000 00 00",
            label4:'Message',
            input4: "Tell us about your project or problem",
            accept: "I Accept Privacy Policy",
            button: "Send",
        },
        Russian: {
            contacts_head: "Напишите нам",
            label1:'Ваше имя*',
            input1: "Иванов Иван",
            label2:'Email*',
            input2: "email@email.com",
            label3:'Телефон',
            input3: "+7 708 671 71 41",
            label4:'Сообщение*',
            input4: "Расскажите нам о проекте или проблеме",
            accept: "Я согласен с политикой конфиденциальности",
            button: "Отправить",
            
        }
    };

    this.props.language === "Russian"
        ? (content = content.Russian)
        : (content = content.English);


     return (
        <div className="form">
            <div className="container">
                <div className="form_head"><h2>{content.contacts_head}</h2></div>
                <form className="form_main">
                    <div className="form_line form_left">
                        <label htmlFor="">{content.label1}</label>
                        <input type="text" placeholder={content.input1}/>
                        <label htmlFor="">{content.label2}</label>
                        <input type="text" placeholder={content.input2}/>
                        <label htmlFor="">{content.label3}</label>
                        <input type="text" placeholder={content.input3}/>
                    </div>
                    <div className="form_line form_right">
                        <label htmlFor="">{content.label4}</label>
                        <input type="text" placeholder={content.input4} />
                        <span><input type="checkbox" name="" id="" />{content.accept}</span>
                        <button>{content.button}</button>
                    </div>
                </form>
            </div>
        </div>
     );
    }
}

export default Form;