/*import React from "react";

export function Navigation(props) {
  return (
    <div className="navigation">
        <select
            className="custom-select"
            value={props.language}
            onChange={e => props.handleSetLanguage(e.target.value)}
            >
            <option value="English">English</option>
            <option value="Russian">Russian</option>
        </select>
    </div>     
  );
}*/


import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function Navigation(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="navigation">
      <Button className={classes.button} onClick={handleOpen}>
    
      </Button>
      <FormControl className={classes.formControl}>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={handleChange}
          className="custom-select"
          value={props.language}
          onChange={e => props.handleSetLanguage(e.target.value)}
        >
          <MenuItem className="nav_value" value="English">
          <svg className="svg" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M27.7532 2.17188H2.24678C1.00588 2.17188 0 3.17775 0 4.41865V19.5803C0 20.8212 1.00588 21.8271 2.24678 21.8271H27.7532C28.9941 21.8271 30 20.8212 30 19.5803V4.41865C30 3.17775 28.9941 2.17188 27.7532 2.17188Z" fill="#F5F5F5"/>
              <path d="M0.123047 3.68383H29.8758C29.5714 2.80393 28.7361 2.17188 27.7527 2.17188H2.24625C1.26281 2.17188 0.427559 2.80393 0.123047 3.68383Z" fill="#FF4B55"/>
              <path d="M30 8.21973H0V9.73162H30V8.21973Z" fill="#FF4B55"/>
              <path d="M30 5.19531H0V6.7072H30V5.19531Z" fill="#FF4B55"/>
              <path d="M0 12.2383C0 12.524 0.231621 12.7556 0.517266 12.7556H30V11.2437H0V12.2383Z" fill="#FF4B55"/>
              <path d="M30 17.2915H0V18.8035H30V17.2915Z" fill="#FF4B55"/>
              <path d="M29.8759 20.3149H0.123047C0.427559 21.1948 1.26287 21.8269 2.24625 21.8269H27.7527C28.7361 21.8269 29.5714 21.1948 29.8759 20.3149Z" fill="#FF4B55"/>
              <path d="M30 14.2676H0V15.7795H30V14.2676Z" fill="#FF4B55"/>
              <path d="M0.517266 12.7555H13.4483C13.734 12.7555 13.9656 12.5239 13.9656 12.2382V2.68914C13.9656 2.40344 13.734 2.17188 13.4483 2.17188H2.24678C1.00588 2.17193 0 3.17781 0 4.41871V12.2382C0 12.5239 0.231621 12.7555 0.517266 12.7555Z" fill="#41479B"/>
              <path d="M1.45233 3.35952L1.56682 3.70288L1.92875 3.70563C1.97563 3.70604 1.99502 3.76581 1.95734 3.79364L1.66619 4.00862L1.77541 4.35368C1.78959 4.39833 1.73867 4.43536 1.70053 4.40805L1.40609 4.19764L1.11166 4.40811C1.07352 4.43542 1.02272 4.39839 1.03678 4.35374L1.146 4.00868L0.854845 3.7937C0.817169 3.76581 0.836564 3.70604 0.883439 3.70569L1.24537 3.70294L1.35986 3.35958C1.37463 3.31505 1.43744 3.31505 1.45233 3.35952Z" fill="#F5F5F5"/>
              <path d="M1.45233 5.15537L1.56682 5.49873L1.92875 5.50149C1.97563 5.5019 1.99502 5.56161 1.95734 5.5895L1.66619 5.80454L1.77541 6.1496C1.78959 6.19424 1.73867 6.23128 1.70053 6.20397L1.40609 5.99356L1.11166 6.20403C1.07352 6.23128 1.02272 6.1943 1.03678 6.14965L1.146 5.80459L0.854845 5.58955C0.817169 5.56166 0.836564 5.50196 0.883439 5.50155L1.24537 5.49879L1.35986 5.15543C1.37463 5.11096 1.43744 5.11096 1.45233 5.15537Z" fill="#F5F5F5"/>
              <path d="M1.45233 6.95229L1.56682 7.29565L1.92875 7.29841C1.97563 7.29882 1.99502 7.35852 1.95734 7.38641L1.66619 7.60145L1.77541 7.94651C1.78959 7.99116 1.73867 8.02819 1.70053 8.00089L1.40609 7.79048L1.11166 8.00095C1.07352 8.02819 1.02272 7.99128 1.03678 7.94657L1.146 7.60151L0.854845 7.38647C0.817169 7.35858 0.836564 7.29888 0.883439 7.29847L1.24537 7.29571L1.35986 6.95235C1.37463 6.90782 1.43744 6.90782 1.45233 6.95229Z" fill="#F5F5F5"/>
              <path d="M1.45233 8.74819L1.56682 9.09155L1.92875 9.0943C1.97563 9.09471 1.99502 9.15448 1.95734 9.18231L1.66619 9.39735L1.77541 9.74241C1.78959 9.78705 1.73867 9.82409 1.70053 9.79678L1.40609 9.58637L1.11166 9.79684C1.07352 9.82409 1.02272 9.78711 1.03678 9.74246L1.146 9.39741L0.854845 9.18237C0.817169 9.1546 0.836564 9.09477 0.883439 9.09436L1.24537 9.09161L1.35986 8.74825C1.37463 8.70372 1.43744 8.70372 1.45233 8.74819Z" fill="#F5F5F5"/>
              <path d="M1.45233 10.544L1.56682 10.8874L1.92875 10.8902C1.97563 10.8906 1.99502 10.9503 1.95734 10.9782L1.66619 11.1932L1.77541 11.5383C1.78959 11.5829 1.73867 11.6199 1.70053 11.5926L1.40609 11.3822L1.11166 11.5927C1.07352 11.6199 1.02272 11.583 1.03678 11.5383L1.146 11.1933L0.854845 10.9782C0.817169 10.9503 0.836564 10.8906 0.883439 10.8902L1.24537 10.8875L1.35986 10.5441C1.37463 10.4996 1.43744 10.4996 1.45233 10.544Z" fill="#F5F5F5"/>
              <path d="M2.84637 4.24034L2.96086 4.5837L3.32279 4.58646C3.36967 4.58687 3.38906 4.64663 3.35139 4.67446L3.06023 4.8895L3.16945 5.23456C3.18363 5.27921 3.13272 5.31624 3.09457 5.28894L2.80014 5.07847L2.5057 5.28894C2.46756 5.31619 2.41676 5.27921 2.43082 5.23456L2.54004 4.8895L2.24889 4.67446C2.21121 4.64657 2.23061 4.58687 2.27748 4.58646L2.63942 4.5837L2.75391 4.24034C2.76867 4.19593 2.83154 4.19593 2.84637 4.24034Z" fill="#F5F5F5"/>
              <path d="M2.84637 6.03628L2.96086 6.37964L3.32279 6.3824C3.36967 6.38281 3.38906 6.44257 3.35139 6.47041L3.06023 6.68544L3.16945 7.0305C3.18363 7.07515 3.13272 7.11218 3.09457 7.08488L2.80014 6.87441L2.5057 7.08488C2.46756 7.11212 2.41676 7.07521 2.43082 7.0305L2.54004 6.68544L2.24889 6.47041C2.21121 6.44263 2.23061 6.38281 2.27748 6.3824L2.63942 6.37964L2.75391 6.03628C2.76867 5.99181 2.83154 5.99181 2.84637 6.03628Z" fill="#F5F5F5"/>
              <path d="M2.84637 7.83267L2.96086 8.17603L3.32279 8.17878C3.36967 8.1792 3.38906 8.2389 3.35139 8.26679L3.06023 8.48183L3.16945 8.82689C3.18363 8.87154 3.13272 8.90857 3.09457 8.88127L2.80014 8.6708L2.5057 8.88127C2.46756 8.90851 2.41676 8.87154 2.43082 8.82689L2.54004 8.48183L2.24889 8.26679C2.21121 8.2389 2.23061 8.1792 2.27748 8.17878L2.63942 8.17603L2.75391 7.83267C2.76867 7.7882 2.83154 7.7882 2.84637 7.83267Z" fill="#F5F5F5"/>
              <path d="M2.84637 9.62853L2.96086 9.97188L3.32279 9.97464C3.36967 9.97505 3.38906 10.0348 3.35139 10.0626L3.06023 10.2777L3.16945 10.6227C3.18363 10.6674 3.13272 10.7044 3.09457 10.6771L2.80014 10.4666L2.5057 10.6771C2.46756 10.7044 2.41676 10.6674 2.43082 10.6227L2.54004 10.2777L2.24889 10.0626C2.21121 10.0348 2.23061 9.97505 2.27748 9.97464L2.63942 9.97188L2.75391 9.62853C2.76867 9.58411 2.83154 9.58411 2.84637 9.62853Z" fill="#F5F5F5"/>
              <path d="M4.24041 3.35953L4.3549 3.70289L4.71684 3.70564C4.76371 3.70605 4.78311 3.76582 4.74543 3.79365L4.45428 4.00863L4.5635 4.35369C4.57768 4.39834 4.52676 4.43537 4.48861 4.40806L4.19418 4.19759L3.89975 4.40806C3.8616 4.43537 3.8108 4.39834 3.82486 4.35369L3.93408 4.00863L3.64293 3.79365C3.60526 3.76576 3.62465 3.70599 3.67152 3.70564L4.03346 3.70289L4.14795 3.35953C4.16272 3.31505 4.22559 3.31505 4.24041 3.35953Z" fill="#F5F5F5"/>
              <path d="M4.24041 5.15538L4.3549 5.49874L4.71684 5.50149C4.76371 5.5019 4.78311 5.56161 4.74543 5.5895L4.45428 5.80454L4.5635 6.1496C4.57768 6.19425 4.52676 6.23128 4.48861 6.20397L4.19418 5.9935L3.89975 6.20397C3.8616 6.23122 3.8108 6.19425 3.82486 6.1496L3.93408 5.80454L3.64293 5.5895C3.60526 5.56161 3.62465 5.5019 3.67152 5.50149L4.03346 5.49874L4.14795 5.15538C4.16272 5.11097 4.22559 5.11097 4.24041 5.15538Z" fill="#F5F5F5"/>
              <path d="M4.24041 6.95132L4.3549 7.29468L4.71684 7.29744C4.76371 7.29785 4.78311 7.35755 4.74543 7.38544L4.45428 7.60048L4.5635 7.94554C4.57768 7.99019 4.52676 8.02722 4.48861 7.99992L4.19418 7.78945L3.89975 7.99992C3.8616 8.02716 3.8108 7.99025 3.82486 7.94554L3.93408 7.60048L3.64293 7.38544C3.60526 7.35755 3.62465 7.29785 3.67152 7.29744L4.03346 7.29468L4.14795 6.95132C4.16272 6.90685 4.22559 6.90685 4.24041 6.95132Z" fill="#F5F5F5"/>
              <path d="M4.24041 8.7482L4.3549 9.09156L4.71684 9.09431C4.76371 9.09472 4.78311 9.15449 4.74543 9.18232L4.45428 9.39736L4.5635 9.74242C4.57768 9.78707 4.52676 9.8241 4.48861 9.79679L4.19418 9.58633L3.89975 9.79679C3.8616 9.82404 3.8108 9.78707 3.82486 9.74242L3.93408 9.39736L3.64293 9.18232C3.60526 9.15455 3.62465 9.09472 3.67152 9.09431L4.03346 9.09156L4.14795 8.7482C4.16272 8.70373 4.22559 8.70373 4.24041 8.7482Z" fill="#F5F5F5"/>
              <path d="M4.24041 10.5441L4.3549 10.8874L4.71684 10.8902C4.76371 10.8906 4.78311 10.9503 4.74543 10.9782L4.45428 11.1932L4.5635 11.5383C4.57768 11.5829 4.52676 11.62 4.48861 11.5926L4.19418 11.3822L3.89975 11.5926C3.8616 11.6199 3.8108 11.5829 3.82486 11.5383L3.93408 11.1932L3.64293 10.9782C3.60526 10.9503 3.62465 10.8906 3.67152 10.8902L4.03346 10.8874L4.14795 10.5441C4.16272 10.4996 4.22559 10.4996 4.24041 10.5441Z" fill="#F5F5F5"/>
              <path d="M5.63494 4.24034L5.74944 4.5837L6.11137 4.58646C6.15824 4.58687 6.17764 4.64663 6.13996 4.67446L5.84881 4.8895L5.95803 5.23456C5.97221 5.27921 5.92129 5.31624 5.88315 5.28894L5.58871 5.07847L5.29428 5.28894C5.25613 5.31619 5.20533 5.27921 5.2194 5.23456L5.32861 4.8895L5.03746 4.67446C4.99979 4.64657 5.01918 4.58687 5.06606 4.58646L5.42799 4.5837L5.54248 4.24034C5.55719 4.19593 5.62012 4.19593 5.63494 4.24034Z" fill="#F5F5F5"/>
              <path d="M5.63494 6.03628L5.74944 6.37964L6.11137 6.3824C6.15824 6.38281 6.17764 6.44257 6.13996 6.47041L5.84881 6.68544L5.95803 7.0305C5.97221 7.07515 5.92129 7.11218 5.88315 7.08488L5.58871 6.87441L5.29428 7.08488C5.25613 7.11212 5.20533 7.07521 5.2194 7.0305L5.32861 6.68544L5.03746 6.47041C4.99979 6.44263 5.01918 6.38281 5.06606 6.3824L5.42799 6.37964L5.54248 6.03628C5.55719 5.99181 5.62012 5.99181 5.63494 6.03628Z" fill="#F5F5F5"/>
              <path d="M5.63494 7.83267L5.74944 8.17603L6.11137 8.17878C6.15824 8.1792 6.17764 8.2389 6.13996 8.26679L5.84881 8.48183L5.95803 8.82689C5.97221 8.87154 5.92129 8.90857 5.88315 8.88127L5.58871 8.6708L5.29428 8.88127C5.25613 8.90851 5.20533 8.87154 5.2194 8.82689L5.32861 8.48183L5.03746 8.26679C4.99979 8.2389 5.01918 8.1792 5.06606 8.17878L5.42799 8.17603L5.54248 7.83267C5.55719 7.7882 5.62012 7.7882 5.63494 7.83267Z" fill="#F5F5F5"/>
              <path d="M5.63494 9.62853L5.74944 9.97188L6.11137 9.97464C6.15824 9.97505 6.17764 10.0348 6.13996 10.0626L5.84881 10.2777L5.95803 10.6227C5.97221 10.6674 5.92129 10.7044 5.88315 10.6771L5.58871 10.4666L5.29428 10.6771C5.25613 10.7044 5.20533 10.6674 5.2194 10.6227L5.32861 10.2777L5.03746 10.0626C4.99979 10.0348 5.01918 9.97505 5.06606 9.97464L5.42799 9.97188L5.54248 9.62853C5.55719 9.58411 5.62012 9.58411 5.63494 9.62853Z" fill="#F5F5F5"/>
              <path d="M7.02905 3.35952L7.14354 3.70288L7.50547 3.70563C7.55235 3.70604 7.57174 3.76581 7.53407 3.79364L7.24291 4.00862L7.35213 4.35368C7.36631 4.39833 7.31539 4.43536 7.27725 4.40805L6.98276 4.19764L6.68832 4.40811C6.65018 4.43542 6.59938 4.39839 6.61344 4.35374L6.72266 4.00868L6.43151 3.7937C6.39383 3.76581 6.41322 3.70604 6.4601 3.70569L6.82203 3.70294L6.93653 3.35958C6.95135 3.31505 7.01422 3.31505 7.02905 3.35952Z" fill="#F5F5F5"/>
              <path d="M7.02905 5.15538L7.14354 5.49874L7.50547 5.50149C7.55235 5.5019 7.57174 5.56161 7.53407 5.5895L7.24291 5.80454L7.35213 6.1496C7.36631 6.19425 7.31539 6.23128 7.27725 6.20397L6.98276 5.9935L6.68832 6.20397C6.65018 6.23122 6.59938 6.19425 6.61344 6.1496L6.72266 5.80454L6.43151 5.5895C6.39383 5.56161 6.41322 5.5019 6.4601 5.50149L6.82203 5.49874L6.93653 5.15538C6.95135 5.11097 7.01422 5.11097 7.02905 5.15538Z" fill="#F5F5F5"/>
              <path d="M7.02905 6.95133L7.14354 7.29469L7.50547 7.29744C7.55235 7.29785 7.57174 7.35756 7.53407 7.38545L7.24291 7.60049L7.35213 7.94555C7.36631 7.9902 7.31539 8.02723 7.27725 7.99992L6.98276 7.7894L6.68832 7.99987C6.65018 8.02711 6.59938 7.99014 6.61344 7.94549L6.72266 7.60043L6.43151 7.38539C6.39383 7.3575 6.41322 7.2978 6.4601 7.29739L6.82203 7.29463L6.93653 6.95127C6.95135 6.90686 7.01422 6.90686 7.02905 6.95133Z" fill="#F5F5F5"/>
              <path d="M7.02905 8.7482L7.14354 9.09156L7.50547 9.09431C7.55235 9.09472 7.57174 9.15449 7.53407 9.18232L7.24291 9.39736L7.35213 9.74242C7.36631 9.78707 7.31539 9.8241 7.27725 9.79679L6.98276 9.58633L6.68832 9.79679C6.65018 9.82404 6.59938 9.78707 6.61344 9.74242L6.72266 9.39736L6.43151 9.18232C6.39383 9.15455 6.41322 9.09472 6.4601 9.09431L6.82203 9.09156L6.93653 8.7482C6.95135 8.70373 7.01422 8.70373 7.02905 8.7482Z" fill="#F5F5F5"/>
              <path d="M7.02905 10.5441L7.14354 10.8874L7.50547 10.8902C7.55235 10.8906 7.57174 10.9503 7.53407 10.9782L7.24291 11.1932L7.35213 11.5383C7.36631 11.5829 7.31539 11.62 7.27725 11.5926L6.98276 11.3822L6.68832 11.5926C6.65018 11.6199 6.59938 11.5829 6.61344 11.5383L6.72266 11.1932L6.43151 10.9782C6.39383 10.9503 6.41322 10.8906 6.4601 10.8902L6.82203 10.8874L6.93653 10.5441C6.95135 10.4996 7.01422 10.4996 7.02905 10.5441Z" fill="#F5F5F5"/>
              <path d="M8.42303 4.24034L8.53752 4.5837L8.89945 4.58646C8.94632 4.58687 8.96572 4.64663 8.92804 4.67446L8.63689 4.8895L8.74611 5.23456C8.76029 5.27921 8.70937 5.31624 8.67123 5.28894L8.3768 5.07847L8.08236 5.28894C8.04422 5.31619 7.99342 5.27921 8.00748 5.23456L8.1167 4.8895L7.82555 4.67446C7.78787 4.64657 7.80727 4.58687 7.85414 4.58646L8.21607 4.5837L8.33057 4.24034C8.34539 4.19593 8.4082 4.19593 8.42303 4.24034Z" fill="#F5F5F5"/>
              <path d="M8.42303 6.03628L8.53752 6.37964L8.89945 6.3824C8.94632 6.38281 8.96572 6.44257 8.92804 6.47041L8.63689 6.68544L8.74611 7.0305C8.76029 7.07515 8.70937 7.11218 8.67123 7.08488L8.3768 6.87441L8.08236 7.08488C8.04422 7.11212 7.99342 7.07521 8.00748 7.0305L8.1167 6.68544L7.82555 6.47041C7.78787 6.44263 7.80727 6.38281 7.85414 6.3824L8.21607 6.37964L8.33057 6.03628C8.34539 5.99181 8.4082 5.99181 8.42303 6.03628Z" fill="#F5F5F5"/>
              <path d="M8.42303 7.83267L8.53752 8.17603L8.89945 8.17878C8.94632 8.1792 8.96572 8.2389 8.92804 8.26679L8.63689 8.48183L8.74611 8.82689C8.76029 8.87154 8.70937 8.90857 8.67123 8.88127L8.3768 8.6708L8.08236 8.88127C8.04422 8.90851 7.99342 8.87154 8.00748 8.82689L8.1167 8.48183L7.82555 8.26679C7.78787 8.2389 7.80727 8.1792 7.85414 8.17878L8.21607 8.17603L8.33057 7.83267C8.34539 7.7882 8.4082 7.7882 8.42303 7.83267Z" fill="#F5F5F5"/>
              <path d="M8.42303 9.62853L8.53752 9.97188L8.89945 9.97464C8.94632 9.97505 8.96572 10.0348 8.92804 10.0626L8.63689 10.2777L8.74611 10.6227C8.76029 10.6674 8.70937 10.7044 8.67123 10.6771L8.3768 10.4666L8.08236 10.6771C8.04422 10.7044 7.99342 10.6674 8.00748 10.6227L8.1167 10.2777L7.82555 10.0626C7.78787 10.0348 7.80727 9.97505 7.85414 9.97464L8.21607 9.97188L8.33057 9.62853C8.34539 9.58411 8.4082 9.58411 8.42303 9.62853Z" fill="#F5F5F5"/>
              <path d="M9.81756 3.35953L9.93205 3.70289L10.294 3.70564C10.3409 3.70605 10.3603 3.76582 10.3226 3.79365L10.0314 4.00863L10.1406 4.35369C10.1548 4.39834 10.1039 4.43537 10.0658 4.40806L9.77133 4.19759L9.47689 4.40806C9.43875 4.43537 9.38795 4.39834 9.40201 4.35369L9.51123 4.00863L9.22008 3.79365C9.1824 3.76576 9.2018 3.70599 9.24867 3.70564L9.61061 3.70289L9.7251 3.35953C9.7398 3.31505 9.80273 3.31505 9.81756 3.35953Z" fill="#F5F5F5"/>
              <path d="M9.81756 5.15538L9.93205 5.49874L10.294 5.50149C10.3409 5.5019 10.3603 5.56161 10.3226 5.5895L10.0314 5.80454L10.1406 6.1496C10.1548 6.19425 10.1039 6.23128 10.0658 6.20397L9.77133 5.9935L9.47689 6.20397C9.43875 6.23122 9.38795 6.19425 9.40201 6.1496L9.51123 5.80454L9.22008 5.5895C9.1824 5.56161 9.2018 5.5019 9.24867 5.50149L9.61061 5.49874L9.7251 5.15538C9.7398 5.11097 9.80273 5.11097 9.81756 5.15538Z" fill="#F5F5F5"/>
              <path d="M9.81756 6.95132L9.93205 7.29468L10.294 7.29744C10.3409 7.29785 10.3603 7.35755 10.3226 7.38544L10.0314 7.60048L10.1406 7.94554C10.1548 7.99019 10.1039 8.02722 10.0658 7.99992L9.77133 7.78945L9.47689 7.99992C9.43875 8.02716 9.38795 7.99025 9.40201 7.94554L9.51123 7.60048L9.22008 7.38544C9.1824 7.35755 9.2018 7.29785 9.24867 7.29744L9.61061 7.29468L9.7251 6.95132C9.7398 6.90685 9.80273 6.90685 9.81756 6.95132Z" fill="#F5F5F5"/>
              <path d="M9.81756 8.7482L9.93205 9.09156L10.294 9.09431C10.3409 9.09472 10.3603 9.15449 10.3226 9.18232L10.0314 9.39736L10.1406 9.74242C10.1548 9.78707 10.1039 9.8241 10.0658 9.79679L9.77133 9.58633L9.47689 9.79679C9.43875 9.82404 9.38795 9.78707 9.40201 9.74242L9.51123 9.39736L9.22008 9.18232C9.1824 9.15455 9.2018 9.09472 9.24867 9.09431L9.61061 9.09156L9.7251 8.7482C9.7398 8.70373 9.80273 8.70373 9.81756 8.7482Z" fill="#F5F5F5"/>
              <path d="M9.81756 10.5441L9.93205 10.8874L10.294 10.8902C10.3409 10.8906 10.3603 10.9503 10.3226 10.9782L10.0314 11.1932L10.1406 11.5383C10.1548 11.5829 10.1039 11.62 10.0658 11.5926L9.77133 11.3822L9.47689 11.5926C9.43875 11.6199 9.38795 11.5829 9.40201 11.5383L9.51123 11.1932L9.22008 10.9782C9.1824 10.9503 9.2018 10.8906 9.24867 10.8902L9.61061 10.8874L9.7251 10.5441C9.7398 10.4996 9.80273 10.4996 9.81756 10.5441Z" fill="#F5F5F5"/>
              <path d="M11.2111 4.24034L11.3256 4.5837L11.6875 4.58646C11.7344 4.58687 11.7538 4.64663 11.7161 4.67446L11.425 4.8895L11.5342 5.23456C11.5484 5.27921 11.4975 5.31624 11.4593 5.28894L11.1649 5.07847L10.8705 5.28894C10.8323 5.31619 10.7815 5.27921 10.7956 5.23456L10.9048 4.8895L10.6136 4.67446C10.576 4.64657 10.5954 4.58687 10.6422 4.58646L11.0042 4.5837L11.1187 4.24034C11.1334 4.19593 11.1963 4.19593 11.2111 4.24034Z" fill="#F5F5F5"/>
              <path d="M11.2111 6.03628L11.3256 6.37964L11.6875 6.3824C11.7344 6.38281 11.7538 6.44257 11.7161 6.47041L11.425 6.68544L11.5342 7.0305C11.5484 7.07515 11.4975 7.11218 11.4593 7.08488L11.1649 6.87441L10.8705 7.08488C10.8323 7.11212 10.7815 7.07521 10.7956 7.0305L10.9048 6.68544L10.6136 6.47041C10.576 6.44263 10.5954 6.38281 10.6422 6.3824L11.0042 6.37964L11.1187 6.03628C11.1334 5.99181 11.1963 5.99181 11.2111 6.03628Z" fill="#F5F5F5"/>
              <path d="M11.2111 7.83267L11.3256 8.17603L11.6875 8.17878C11.7344 8.1792 11.7538 8.2389 11.7161 8.26679L11.425 8.48183L11.5342 8.82689C11.5484 8.87154 11.4975 8.90857 11.4593 8.88127L11.1649 8.6708L10.8705 8.88127C10.8323 8.90851 10.7815 8.87154 10.7956 8.82689L10.9048 8.48183L10.6136 8.26679C10.576 8.2389 10.5954 8.1792 10.6422 8.17878L11.0042 8.17603L11.1187 7.83267C11.1334 7.7882 11.1963 7.7882 11.2111 7.83267Z" fill="#F5F5F5"/>
              <path d="M11.2111 9.62853L11.3256 9.97188L11.6875 9.97464C11.7344 9.97505 11.7538 10.0348 11.7161 10.0626L11.425 10.2777L11.5342 10.6227C11.5484 10.6674 11.4975 10.7044 11.4593 10.6771L11.1649 10.4666L10.8705 10.6771C10.8323 10.7044 10.7815 10.6674 10.7956 10.6227L10.9048 10.2777L10.6136 10.0626C10.576 10.0348 10.5954 9.97505 10.6422 9.97464L11.0042 9.97188L11.1187 9.62853C11.1334 9.58411 11.1963 9.58411 11.2111 9.62853Z" fill="#F5F5F5"/>
              <path d="M12.6056 3.35953L12.7201 3.70289L13.0821 3.70564C13.1289 3.70605 13.1483 3.76582 13.1107 3.79365L12.8195 4.00863L12.9287 4.35369C12.9429 4.39834 12.892 4.43537 12.8538 4.40806L12.5594 4.19759L12.265 4.40806C12.2268 4.43537 12.176 4.39834 12.1901 4.35369L12.2993 4.00863L12.0082 3.79365C11.9705 3.76576 11.9899 3.70599 12.0368 3.70564L12.3987 3.70289L12.5132 3.35953C12.528 3.31505 12.5908 3.31505 12.6056 3.35953Z" fill="#F5F5F5"/>
              <path d="M12.6056 5.15538L12.7201 5.49874L13.0821 5.50149C13.1289 5.5019 13.1483 5.56161 13.1107 5.5895L12.8195 5.80454L12.9287 6.1496C12.9429 6.19425 12.892 6.23128 12.8538 6.20397L12.5594 5.9935L12.265 6.20397C12.2268 6.23122 12.176 6.19425 12.1901 6.1496L12.2993 5.80454L12.0082 5.5895C11.9705 5.56161 11.9899 5.5019 12.0368 5.50149L12.3987 5.49874L12.5132 5.15538C12.528 5.11097 12.5908 5.11097 12.6056 5.15538Z" fill="#F5F5F5"/>
              <path d="M12.6056 6.95132L12.7201 7.29468L13.0821 7.29744C13.1289 7.29785 13.1483 7.35755 13.1107 7.38544L12.8195 7.60048L12.9287 7.94554C12.9429 7.99019 12.892 8.02722 12.8538 7.99992L12.5594 7.78945L12.265 7.99992C12.2268 8.02716 12.176 7.99025 12.1901 7.94554L12.2993 7.60048L12.0082 7.38544C11.9705 7.35755 11.9899 7.29785 12.0368 7.29744L12.3987 7.29468L12.5132 6.95132C12.528 6.90685 12.5908 6.90685 12.6056 6.95132Z" fill="#F5F5F5"/>
              <path d="M12.6056 8.7482L12.7201 9.09156L13.0821 9.09431C13.1289 9.09472 13.1483 9.15449 13.1107 9.18232L12.8195 9.39736L12.9287 9.74242C12.9429 9.78707 12.892 9.8241 12.8538 9.79679L12.5594 9.58633L12.265 9.79679C12.2268 9.82404 12.176 9.78707 12.1901 9.74242L12.2993 9.39736L12.0082 9.18232C11.9705 9.15455 11.9899 9.09472 12.0368 9.09431L12.3987 9.09156L12.5132 8.7482C12.528 8.70373 12.5908 8.70373 12.6056 8.7482Z" fill="#F5F5F5"/>
              <path d="M12.6056 10.5441L12.7201 10.8874L13.0821 10.8902C13.1289 10.8906 13.1483 10.9503 13.1107 10.9782L12.8195 11.1932L12.9287 11.5383C12.9429 11.5829 12.892 11.62 12.8538 11.5926L12.5594 11.3822L12.265 11.5926C12.2268 11.6199 12.176 11.5829 12.1901 11.5383L12.2993 11.1932L12.0082 10.9782C11.9705 10.9503 11.9899 10.8906 12.0368 10.8902L12.3987 10.8874L12.5132 10.5441C12.528 10.4996 12.5908 10.4996 12.6056 10.5441Z" fill="#F5F5F5"/>
            </svg>
          
            </MenuItem>
          <MenuItem className="nav_value" value="Russian">
            <svg className="svg" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M27.7532 2.17236H2.24678C1.00588 2.17236 0 3.17824 0 4.41914V8.72408H30V4.41914C30 3.17824 28.9941 2.17236 27.7532 2.17236Z" fill="#F5F5F5"/>
              <path d="M0 19.5808C0 20.8217 1.00588 21.8276 2.24678 21.8276H27.7532C28.9941 21.8276 30 20.8217 30 19.5808V15.2759H0V19.5808Z" fill="#FF4B55"/>
              <path d="M30 8.72412H0V15.2755H30V8.72412Z" fill="#41479B"/>
            </svg>
           </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}


export default Navigation;