import './App.css';
import React, { useState } from "react";
import First from "./components/site/First";
import { Header } from "./components/header/Header";
import  Navigation from "./components/header/Navigation";
import  Menu from "./components/header/Menu";
import { Burger } from "./components/header/Burger";
import { Services } from './components/site/Services';
import Technologies from './components/site/Technologies';
import { Clients } from './components/site/Clients';
import { Projects } from './components/site/Projects';
import Team from './components/site/Team';
import Reviews from './components/site/Reviews';
import Questions from './components/site/Questions';
import Form from './components/site/Form';
import Footer from './components/site/Footer';

const App = () => {
  /*const [open, setOpen] = React.useState(false);
  const node = React.useRef();*/

  let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : "English"
  );

  /*сonst [menuActive, setMenuActive] = useState(false);*/

  const [menuActive, setMenuActive] = useState(false);

  return (
    <div className="App">
        <Header language={language}>
          <div className="container">
            <Menu language={language} />
            <Navigation
              language={language}
              handleSetLanguage={language => {
                setLanguage(language);
                storeLanguageInLocalStorage(language);
              }}
            /> 
          </div>
          <nav>
            <div className="burger" onClick={()=> setMenuActive(!menuActive)}>
              <span></span>
            </div>
            <Burger active={menuActive} setActive={setMenuActive} language={language} />
          </nav>
        </Header>
        <First className="first">
        </First> 
        <Services language={language}></Services>
        <Technologies/>
        <Clients language={language}/>
        <Projects language={language}/>
        <Team language={language}/>
        <Reviews language={language}/>
        <Questions/>
        <Form  language={language}/>
        <Footer/>
    </div>
  );
}

function storeLanguageInLocalStorage(language) {
  localStorage.setItem("language", language);
}

export default App;


