import React from "react";
/*import { slide as Menu } from 'react-burger-menu'*/

/*class Menu extends React.Component {
  showSettings (event) {
  }

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu>
        <a id="home" className="menu-item" href="/">Home</a>
        <a id="about" className="menu-item" href="/about">About</a>
        <a id="contact" className="menu-item" href="/contact">Contact</a>
        <a onClick={ this.showSettings } className="menu-item--small" href="">Settings</a>
      </Menu>
    );
  }
}

export default Menu;*/

export function Menu(props) {
  let content = {
    English: {
        link_1: "Services",
        link_2: "Technologies",
        link_3: "Cases",
        link_4: "Team",
        link_5: "FAQ",
        link_6: "Contacts"
    },
    Russian: {
        link_1: "Услуги",
        link_2: "Технологии",
        link_3: "Кейсы",
        link_4: "Команда",
        link_5: "FAQ",
        link_6: "Контакты"
    }
  };

  props.language === "Russian"
    ? (content = content.Russian)
    : (content = content.English);

  return (
    <ul className="menu">
        <li><a href="">{content.link_1}</a></li>
        <li><a href="">{content.link_2}</a></li>
        <li><a href="">{content.link_3}</a></li>
        <li><a href="">{content.link_4}</a></li>
        <li><a href="">{content.link_5}</a></li>
        <li><a href="">{content.link_6}</a></li>
    </ul>
  );
}

export default Menu;