import React, { Component } from "react";
import Slider from "react-slick";
import slide1 from '../img/slide1.png' // relative path to image
import slide2 from '../img/slide2.png' // relative path to image 
import slide3 from '../img/slide3.png' // relative path to image 
import slide4 from '../img/slide4.png' // relative path to image 
import slide5 from '../img/slide5.png' // relative path to image 
import slide6 from '../img/slide6.png' // relative path to image
import slide7 from '../img/slide7.png' // relative path to image 


export default class Technologies extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      autoplay: true,
      autoplaySpeed: 1000,
      pauseOnHover: true,
       responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        }
      ]
    };
    return (
      <div className="container tech">
        <div className="slider">
          <Slider {...settings}>
            <div className="slide_over">
              <div className="slide">
                <img src={slide1} alt={"slide"}/>
                <p>Angular</p>
              </div>
            </div>
            <div className="slide_over">
              <div className="slide">
                <img src={slide2} alt={"slide"}/>
                <p>NodeJS</p>
              </div>
            </div>
            <div className="slide_over">
              <div className="slide">
                <img src={slide3} alt={"slide"}/>
                <p>Python</p>
              </div>
            </div>
            <div className="slide_over">
              <div className="slide">
                <img src={slide4} alt={"slide"}/>
                <p>PostgreSQL</p>
              </div>
            </div>
            <div className="slide_over">
              <div className="slide">
                <img src={slide5} alt={"slide"}/>
                <p>Laravel</p>
              </div>
            </div>
            <div className="slide_over">
              <div className="slide">
                <img src={slide6} alt={"slide"}/>
                <p>JavaScript</p>
              </div>
            </div>
            <div className="slide_over">
              <div className="slide">
                <img src={slide7} alt={"slide"}/>
                <p>Django</p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}