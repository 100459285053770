import React, { Component } from "react";
class Questions extends React.Component {
    render() {
        const hiddenTexts = [{
            label: 'What kind of sites do you develop?',
            value: 'Text of Accordion 1'
        },
        {
            label: 'What kind of sites do you develop?',
            value: 'Text of Accordion 2'
        },
        {
            label: 'What kind of sites do you develop?',
            value: 'Text of Accordion 3'
        }
        ];
        return (
            <div>
                <Accordion hiddenTexts={hiddenTexts} />
            </div>
        );
    }
}


class Accordion extends React.Component {
    render() {
        return (
            <div className="accordion">
                <div className="accordion-header">
                    <h2>Frequently Asked Questions</h2>
                    <h3>FAQ</h3>
                </div>
                <div className="container">
                    <div className="accrdion_line">
                        {this.props.hiddenTexts.map((hiddenText) => <AccordionItem key={hiddenText.label} hiddenText={hiddenText} />)}
                    </div>
                    <div className="accrdion_line">
                        {this.props.hiddenTexts.map((hiddenText) => <AccordionItem key={hiddenText.label} hiddenText={hiddenText} />)}
                    </div>
                    </div>
            </div>
        );
    }
}

class AccordionItem extends React.Component {
    
    constructor(props) {
        super(props);
        this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
        this.state = {
            visibility: false
        }
    }
    
    handleToggleVisibility() {
        this.setState((prevState) => {
            return {
                visibility: !prevState.visibility,
            }
        })
    }
    render() {
        const activeStatus = this.state.visibility ? 'active' : ''

        return (
           
                    <div className="accordion_line">
                        <button className="accordion__button" onClick={this.handleToggleVisibility}>{this.props.hiddenText.label}
                            <span className={this.state.visibility? 'fas fa-minus': 'fas fa-plus'}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 7H9V1C9 0.734784 8.89464 0.48043 8.70711 0.292893C8.51957 0.105357 8.26522 0 8 0C7.73478 0 7.48043 0.105357 7.29289 0.292893C7.10536 0.48043 7 0.734784 7 1V7H1C0.734784 7 0.48043 7.10536 0.292893 7.29289C0.105357 7.48043 0 7.73478 0 8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H7V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V9H15C15.2652 9 15.5196 8.89464 15.7071 8.70711C15.8946 8.51957 16 8.26522 16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536 15.2652 7 15 7Z" fill="white"/>
                            </svg>
                            </span>
                        </button>
                        <p className={`accordion__content ${activeStatus}`}>
                        {
                            this.props.hiddenText.value
                        }
                        </p>
                    </div>

        
        );
    }
}

export default Questions;