import React from "react";
import logo1 from '../img/logo1.png' // relative path to image
import project1 from '../img/project1.png' // relative path to image
import logo2 from '../img/logo2.png' // relative path to image
import project2 from '../img/project2.png' // relative path to image
import logo3 from '../img/logo3.png' // relative path to image
import project3 from '../img/project3.png' // relative path to image

export function Projects(props) {
  let content = {
    English: {
      project1_head: 'Landing Page for FlyCo - English Language School',
      project2_head: 'Site re-design for photoschool MonoChrome',
      project3_head: 'Site for Chinese Language School Chen-Laoshi',
      project4_head: 'Design for Cafe App (Android) ',
      paragraph1:'UI/UX',
      paragraph2:'Design System',
      paragraph3:'SEO-Optimization',
      paragraph4:'Site Layout',
      paragraph5:'Customer Journey Map',
      paragraph6:'Wireframes',
      button: 'View Case'
    },
    Russian: {
      project1_head: 'Лендинг для FlyCo - школы английского языка',
      project2_head: 'Редизайн сайта фотошколы MonoChrome',
      project3_head: 'Сайт для школы китайского языка  Chen-Laoshi',
      project4_head: 'Дизайн для приложения кофе на вынос (Android) ',
      paragraph1:'UI/UX',
      paragraph2:'Design System',
      paragraph3:'SEO-Optimization',
      paragraph4:'Site Layout',
      paragraph5:'Customer Journey Map',
      paragraph6:'Wireframes',
      button: 'Смотреть кейс'
    }
  };

  props.language === "Russian"
    ? (content = content.Russian)
    : (content = content.English);

  return (
   <div className="projects">
       <div className="container">
            <div className="project project1">
                <div className="project_info">
                    <div className="project_text">
                        <h2>{content.project1_head}</h2>
                        <ul>
                            <li>{content. paragraph1}</li>
                            <li>{content. paragraph2}</li>
                            <li>{content. paragraph3}</li>
                        </ul>
                        <button className="button1">{content.button}</button>
                    </div>
                    <div className="project_img">
                        <img src={project1} alt={"project"} />
                        <button className = "button2">{content.button}</button>
                    </div>
                </div>
                <img className="project-logo" src={logo1} alt={"laptop"}/> 
            </div>
            <div className="project project2">
                <div className="project_info">
                    <div className="project_text">
                        <h2>{content.project2_head}</h2>
                        <ul>
                            <li>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.22203 22L18 12L8.22203 2L7 3.24978L15.5559 12L7 20.7502L8.22203 22Z" fill="#0A172A"/>
                                </svg>
                                {content. paragraph1}
                            </li>
                            <li>
                            <   svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.22203 22L18 12L8.22203 2L7 3.24978L15.5559 12L7 20.7502L8.22203 22Z" fill="#0A172A"/>
                                </svg>
                                {content. paragraph2}
                            </li>
                            <li>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.22203 22L18 12L8.22203 2L7 3.24978L15.5559 12L7 20.7502L8.22203 22Z" fill="#0A172A"/>
                                </svg>
                                {content. paragraph3}
                            </li>
                        </ul>
                        <button className="button1">{content.button}</button>
                    </div>
                    <div className="project_img">
                        <img src={project2} alt={"project"} />
                        <button className = "button2">{content.button}</button>
                    </div>
                </div>
                <img className="project-logo" src={logo2} alt={"laptop"}/> 
            </div>
            <div className="project project3">
                <div className="project_info">
                    <div className="project_text">
                        <h2>{content.project3_head}</h2>
                        <ul>
                            <li>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.22203 22L18 12L8.22203 2L7 3.24978L15.5559 12L7 20.7502L8.22203 22Z" fill="white"/>
                            </svg>

                                {content.paragraph1}
                            </li>
                            <li>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.22203 22L18 12L8.22203 2L7 3.24978L15.5559 12L7 20.7502L8.22203 22Z" fill="white"/>
                                </svg>
                                {content.paragraph2}
                            </li>
                            <li>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.22203 22L18 12L8.22203 2L7 3.24978L15.5559 12L7 20.7502L8.22203 22Z" fill="white"/>
                                </svg>
                                {content.paragraph3}
                            </li>
                        </ul>
                        <button className="button3">{content.button}</button>
                    </div>
                    <div className="project_img">
                        <img src={project3} alt={"project"} />
                        <button className = "button2">{content.button}</button>
                    </div>
                </div>
                <img className="project-logo" src={logo3} alt={"laptop"}/> 
            </div>
       </div>
   </div>
  );
}

export default Projects;