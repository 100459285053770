import React from "react";
import client1 from '../img/client1.png' // relative path to image
import client_1 from '../img/client_1.png' // relative path to image
import client2 from '../img/client2.png' // relative path to image
import client_2 from '../img/client_2.png' // relative path to image
import client3 from '../img/client3.png' // relative path to image
import client_3 from '../img/client_3.png' // relative path to image
import client4 from '../img/client4.png' // relative path to image
import client_4 from '../img/client_4.png' // relative path to image
import client5 from '../img/client5.png' // relative path to image
import client_5 from '../img/client_5.png' // relative path to image

export function Clients(props) {
  let content = {
    English: {
      header: 'Our services have been helping businesses with digital solutions'
    },
    Russian: {
        header: 'Наши услуги помогают бизнесу развиваться динамичнее '
    }
  };

  props.language === "Russian"
    ? (content = content.Russian)
    : (content = content.English);

  return (
   <div className="clients">
       <div className="container">
           <div className="clients_head">
               <h2>{content.header}</h2>
           </div>
           <div className="clients_body">
              <div className="clients_item">
                <img className='main-img' src={client2} alt={"image"}/>
                <img className='hover-img' src={client_2} alt={"image"}/>
                <div className="clients_text">
                  <p>iRental</p>
                </div>
              </div> 
              <div className="clients_item">
                <img className='main-img' src={client1} alt={"image"}/>
                <img className='hover-img' src={client_1} alt={"image"}/>
                <div className="clients_text">
                  <p>Chen-Laoshi - Chinese Language Courses</p>
                </div>
              </div>  
              <div className="clients_item">
                <img className='main-img' src={client3} alt={"image"}/>
                <img className='hover-img' src={client_3} alt={"image"}/>
                <div className="clients_text">
                  <p>LionArto - Wooden Home Decor Shop</p>
                </div>
              </div> 
              <div className="clients_item">
                <img className='main-img' src={client4} alt={"image"}/>
                <img className='hover-img' src={client_4} alt={"image"}/>
                <div className="clients_text">
                  <p>Monochrome Photo School in Almaty</p>
                </div>
              </div> 
              <div className="clients_item">
                <img className='main-img' src={client5} alt={"image"}/>
                <img className='hover-img' src={client_5} alt={"image"}/>
                <div className="clients_text">
                  <p>Monochrome Photo School in Almaty</p>
                </div>
              </div> 
           </div>
       </div>
   </div>
  );
}

export default Clients;