import React from "react";

export function Header(props) {
  let content = {
    English: {
      title: "Lorem",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    Russian: {
      title: "Что-то на русском",
      description:
        "Что-то на русском но больше"
    }
  };

  props.language === "Russian"
    ? (content = content.Russian)
    : (content = content.English);

  return (
    <header className="header">
        {props.children}
    </header>
  );
}
